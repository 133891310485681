/* eslint no-extend-native: 0 */
// core-js comes with Next.js. So, you can import it like below
import URLSearchParams from '@ungap/url-search-params'
import stringIncludes from 'core-js/library/fn/string/virtual/includes'
import arrayIncludes from 'core-js/library/fn/array/virtual/includes'
import from from 'core-js/library/fn/array/from'
import assign from 'core-js/library/fn/object/assign'
import keys from 'core-js/library/fn/object/keys'
import values from 'core-js/library/fn/object/values'

// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)

window.URLSearchParams = URLSearchParams
String.prototype.includes = stringIncludes
Array.from = from
Array.prototype.includes = arrayIncludes
Object.keys = keys
Object.values = values
Object.assign = assign
